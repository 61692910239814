<script lang="ts">
// eslint-disable-next-line import/no-duplicates
import Vue from 'vue';
// eslint-disable-next-line import/no-duplicates
import { PropType } from 'vue';

const UNIT_SECOND = 'second';
const UNIT_MINUTE = 'minute';
const UNIT_HOUR = 'hour';
const UNIT_DAY = 'day';
const UNIT_WEEK = 'week';

const SECOND_FACTOR = 1;
const MINUTE_FACTOR = SECOND_FACTOR*60;
const HOUR_FACTOR = MINUTE_FACTOR*60;
const DAY_FACTOR = HOUR_FACTOR*24;
const WEEK_FACTOR = DAY_FACTOR*7;

const UNIT_TO_FACTOR: Map<string, number> =  new Map([
    [UNIT_SECOND, 1],
    [UNIT_MINUTE, MINUTE_FACTOR],
    [UNIT_HOUR, HOUR_FACTOR],
    [UNIT_DAY, DAY_FACTOR],
    [UNIT_WEEK, WEEK_FACTOR],
]);

export class ActionParamsTrigger {
    name = 'trigger/staleConversation';

    timeout: number|null = null;

    config: {
        preset: string|undefined|null;
        amount: number;
        unit: string;
        custom: boolean;
    }|null = null;
}

export default Vue.extend({
    name: 'TriggerConfigurator',
    props: {
        value: {
          type: Object as PropType<ActionParamsTrigger>,
        },
    },
    data() {
        return {
            enabled: false,
            preset: null as string | null | undefined,
            presets: [
                { amount: 1, unit: UNIT_HOUR, label: '1 hour' },
                { amount: 4, unit: UNIT_HOUR, label: '4 hours' },
                { amount: 1, unit: UNIT_DAY, label: '1 day' },
                { amount: 2, unit: UNIT_DAY, label: '2 days' },
                { amount: 1, unit: UNIT_WEEK, label: '1 week' },
                { amount: 4, unit: UNIT_WEEK, label: '4 weeks' },
            ],
            units: [
                {text: 'second', value: UNIT_SECOND},
                {text: 'minute', value: UNIT_MINUTE},
                {text: 'hour', value: UNIT_HOUR},
                {text: 'day', value: UNIT_DAY},
                {text: 'week', value: UNIT_WEEK},
            ],
            custom: true,
            amount: 2,
            unit: UNIT_WEEK,
        }
    },
    methods: {
        setConfig(amount: number, unit: string): void
        {
            this.custom = false;
            this.amount = amount;
            this.unit = unit;
        },
    },
    watch: {
        triggerParams(params: ActionParamsTrigger|null): void {
            this.$emit('input', params);
        }
    },
    beforeMount() {
        this.enabled = this.value !== null;
        this.preset = this.value?.config?.preset;
        this.custom = this.value?.config?.custom ?? true;
        this.unit = this.value?.config?.unit ?? UNIT_WEEK;
        this.amount = this.value?.config?.amount ?? 2;
    },
    computed: {
        intervalNumber(): number|null {
            if (!this.enabled) {
                return null;
            }

            const factor = UNIT_TO_FACTOR.get(this.unit) ?? 0;

            return this.amount * factor;
        },
        triggerParams(): ActionParamsTrigger|null {
            if (!this.enabled) {
                return null;
            }

            return {
                name: 'trigger/staleConversation',
                timeout: this.intervalNumber,
                config: {
                    preset: this.preset,
                    custom: this.custom,
                    amount: this.amount,
                    unit: this.unit,
                }
            };
        }
    },
})
</script>

<template>
    <div>
        <v-row>
            <v-col>
                <v-switch
                    label="Enable Stale Trigger"
                    v-model="enabled"
                />
            </v-col>
        </v-row>
        <v-row v-if="enabled">
            <v-col>
                <p>Deadline</p>
                <v-btn-toggle v-model="preset" mandatory>
                    <v-btn v-for="{amount, unit, label} in presets" v-bind:key="`${amount}_${unit}`"
                           :value="`${amount}_${unit}`"
                           @click="setConfig(amount, unit)">
                        {{ label }}
                    </v-btn>
                    <v-btn value="custom" @click="custom = true">
                        Custom
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row class="mt-2" dense v-if="enabled && custom" no-gutters>
            <v-col cols="1">
                <v-text-field
                    filled
                    type="number"
                    label="amount"
                    min="1"
                    v-model.number="amount"
                    placeholder="2"/>
            </v-col>
            <v-col cols="1">
                <v-select
                    filled
                    item-text="text"
                    item-value="value"
                    label="unit"
                    v-model="unit"
                    :items="units">
                    <template #item="{ item }">
                        {{ item.text }}{{ (amount > 1 ? 's' : '') }}
                    </template>
                    <template #selection="{ item }">
                        {{ item.text }}{{ (amount > 1 ? 's' : '') }}
                    </template>
                </v-select>
            </v-col>
        </v-row>
    </div>
</template>

<style scoped lang="scss">

</style>
